.authentication_page {
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.authentication_layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.component_side_layout {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.authentication_input_side {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 75px;
  padding-top: 20px;
  padding-bottom: 50px;
  overflow: auto;
}

.authentication_input_layout {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  /* height: 100%; */
}

.input_options {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  flex: 1;
}

.authentication_content_side {
  display: flex;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  box-sizing: border-box;
  padding: 50px;
  padding-top: 0px;
}

.authentication_content_text {
  color: white;
  font-size: 65px;
  font-weight: 200;
  text-align: start;
  user-select: none;
}

.home_header_content_right {
  position: absolute;
  display: flex;
  justify-content: end;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.home_components {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.logo_icon {
  height: 100px;
  margin-bottom: 30px;
  margin-left: -20px;
}

.landing_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.home_header_content {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.home_header_content_right {
  position: absolute;
  display: flex;
  justify-content: end;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* .page_title {
  color: #0058dc;
  font-weight: 200;
  font-size: 30px;
  margin-bottom: 50px;
} */
.page_title {
  color: black;
  font-weight: 200;
  font-size: 32px;
  opacity: 0.75;
  margin: 0;
  margin-bottom: 8px;
  text-align: start;
  user-select: none;
}

.signin_page_title {
  color: black;
  font-weight: 200;
  font-size: 32px;
  opacity: 0.75;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 20px;
  user-select: none;
}

.page_subtitle {
  color: black;
  font-weight: 200;
  font-size: 20px;
  margin: 0;
  margin-bottom: 20px;
  opacity: 0.5;
  user-select: none;
}

.sidebar {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 75px;
  backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
}

.sidebar_right {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 45%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    1
  ); /* Semi-transparent white background */

  box-sizing: border-box;
  padding: 30px;
  backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
}

.sidebar_subscription {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 50%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: rgba(0, 0, 0, 1);
  border-left: 2px solid white;
  box-sizing: border-box;
  padding: 30px;
  backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
}

.main_copywriting {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.two_column_inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.half_input_object {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 48%;
}

.input_text_input {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 55px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  /* background: rgba(0, 0, 0, 0.05); */
  margin-bottom: 15px;
  box-sizing: border-box;
  padding-right: 10px;
  font-size: 18px;
}

.email_input {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  /* background: rgba(0, 0, 0, 0.05); */
  margin-bottom: 15px;
  box-sizing: border-box;
  padding-right: 10px;
}

.dropdown_input {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 42px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  /* background: rgba(0, 0, 0, 0.05); */
  margin-bottom: 15px;
  box-sizing: border-box;
  padding-right: 10px;

  cursor: pointer;
}

.dropdown_input:hover {
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

.paragraph_input {
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  /* background: rgba(0, 0, 0, 0.05); */
  /* margin-bottom: 15px; */
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.input {
  background-color: transparent;
  margin-left: 10px;
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: gray;
}

.readonly_dropdown_input {
  background-color: transparent;
  margin-left: 10px;
  flex-grow: 1;
  border: none;
  outline: none;
  user-select: none;
  font-family: Arial, Helvetica, sans-serif;
  color: gray;
  cursor: pointer;
}

.textarea_input {
  background-color: transparent;
  /* margin-left: 10px; */
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  /* margin-right: 20px; */
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.input::placeholder {
  color: black;
  opacity: 0.2;
  font-weight: 400;
}

.dropdown_input_option {
  width: 100%;
}

.dropdown_input_element {
  border: none;
  outline: none;
  flex-grow: 1;
  margin-left: 15px;
}

/* Add this to your CSS file, or create a new CSS file if needed */
.dropdown_options {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid #ccc;
  width: 50%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  background-color: #fff;
}

.dropdown_item {
  display: flex;
  justify-content: start;
  /* height: 55px; */
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.dropdown_item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.page_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 0);
  color: white;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.pay_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
  color: white;
  margin-top: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 15px;
}

.pay_button:hover {
  border: 1px solid rgba(0, 0, 0, 1);
  color: rgba(0, 0, 0, 1);
  background-color: rgba(0, 0, 0, 0.4);
}

.page_button_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 0);
  color: white;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.page_button:hover {
  border: 1px solid rgba(0, 0, 0, 1);
  color: rgba(0, 0, 0, 1);
  background-color: rgba(0, 0, 0, 0.4);
}

.page_button_inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  /* background: rgba(0, 0, 0, 0.2); */
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 1);
  opacity: 0.5;
  margin-top: 20px;
  margin-bottom: 10px;
}

.join_button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.join_button_text {
  font-size: 15px;
  font-weight: 400;
  user-select: none;
}

.clickable_text {
  color: rgba(0, 0, 0, 1);
  text-decoration: underline;
  cursor: pointer;
}

.loader_layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.example_note_gray {
  text-align: start;
  margin: 0;
  font-size: 15px;
  font-weight: 200;
  opacity: 0.75;
  margin-bottom: 25px;
}

@media (max-width: 1100px) {
  .sidebar {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    background-color: white;

    box-sizing: border-box;
    padding: 30px;
  }
}

.logo {
  width: 100px;
  object-fit: cover;
}

.login_input_titles {
  /* height: 60px; */
  margin: 0;
  margin-bottom: 8px;
  opacity: 0.4;
  text-align: start;
  user-select: none;
}

.error_text {
  color: rgba(200, 0, 0, 0.8);
  margin: 0;
}

.auth_input_icon {
  margin-left: 20px;
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.subscription_plans {
  width: 100%;
}

.profile_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.proflie_picture_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.update_profile_picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 30px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-color: rgba(0, 0, 0, 0.35); */
  border: 1px solid rgba(0, 0, 0, 0.6);
  margin-left: 30px;
  border-radius: 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  cursor: pointer;
}

.profile_edit_section {
  display: flex;
}

.profile_picture_bg {
  width: 75px;
  height: 75px;
  border-radius: 40%;
}

.profile_picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.update_profile_picture:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
}

.prompt_texts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.note {
  color: rgba(0, 0, 0, 0.4);
  opacity: 0.75;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  /* text-decoration: underline; */
  cursor: pointer;
  margin: 0;
  margin-top: 5px;
}

.forgot_password {
  color: rgba(0, 0, 0, 0.4);
  opacity: 0.75;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  user-select: none;
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
  margin-top: 5px;
}

.forgot_password:hover {
  color: rgba(0, 0, 0, 0.7);
  opacity: 0.75;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  cursor: pointer;
}

.page_flexbox {
  display: flex;
  box-sizing: border-box;
  justify-content: start;
  align-items: start;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  flex: 1;
  /* background-color: red; */
}

.payment_options_layout {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  /* 
  min-height: 300px; */

  /* box-sizing: border-box; */

  width: 100%;
  /* overflow: hidden; */
  padding-bottom: 30px;

  max-height: calc(100vh - 375px);
}

.select_options_layout {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: start;
  align-items: start;
  gap: 20px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 30px;
  overflow-y: auto;
  max-height: calc(100vh - 375px);
}

.user_input_topics_layout {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  /* padding: 20px; */
  gap: 20px;
  width: 100%;
  /* margin-top: 15px; */
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  /* background-color: rgba(0, 0, 0,  0.1); */
  margin-bottom: 30px;
  max-height: 400px;
  overflow-y: auto;
  padding-bottom: 30px;
}

.list_option {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px 10px;
  border-radius: 2.5px;
  height: 30px;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.list_option_selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: rgba(0, 0, 0,  0.1); */
  padding: 5px 10px;
  border-radius: 2.5px;
  height: 30px;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.list_option:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.list_option_text {
  color: black;
  font-size: 15px;
  font-weight: 200;
  margin: 0;
  user-select: none;
}

.cancel_icon_bg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.01);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
}

/* .cancel_icon_bg:hover {
  background-color: rgba(0, 0, 0, 0.075);
} */

.cross_svg {
  stroke: rgba(0, 0, 0, 0.3);
  opacity: 0.8;
}

/* .spacer {
  display: flex;

  background-color: red;
  width: 100%;
  height: 40px;
} */

.input_phrase_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0 0px;
  margin-bottom: 20px;
}

.add_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70%;
  box-sizing: border-box;
  border-radius: 0px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  cursor: pointer;
  margin-right: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}

.add_button:hover {
  background-color: rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 1);
}

.input_banned_phrase {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 200;
}

.payment_option_value_props {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two columns */
  gap: 8px;
  width: 100%;
  align-items: start; /* Align items to the start of each grid cell */
  margin-bottom: 30px;
}

.payment_option_value_prop {
  color: black;
  font-size: 12px;
  font-weight: 200;
  margin: 0;
  margin-left: 12px;
  user-select: none;
  /* color: rgba(0, 0, 0, 1); */
  text-align: start;
}

.payment_option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  border-radius: 2.5px;
  cursor: pointer;
  gap: 7px;
  position: relative;
}

.payment_option:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.payment_option_text {
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-weight: 200;
  margin: 0;
  user-select: none;
}

.payment_option_text_price {
  color: black;
  font-size: 20px;
  font-weight: 200;
  margin: 0;
  user-select: none;
  color: rgba(0, 0, 0, 1);
}

.payment_option_selected {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 1);
  width: 100%;
  height: 100%;
  border-radius: 2.5px;
  gap: 7px;
  position: relative;
}

.check_icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  transform: translate(50%, -50%);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 1);
  box-sizing: border-box;
  padding: 4px;
}

.progress_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  height: 55px;
  margin-top: 25px;
}

.progress_bar {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 1);
}

.progress_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 55px;
  /* min-height: 55px; */
  border-radius: 55px;
  /* border: 1px solid rgba(0, 0, 0, 1); */
}

.progress_percent {
  margin: 0;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
}

.checkout_form {
  width: 100%;
}

.valueProp_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown_menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 100%;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  z-index: 999999;
  max-height: 180px;
  overflow-y: auto;
}

.dropdown_icon {
  width: 25px;
  height: 25px;
  margin-right: 7.5px;
}

.dropdown_icon_background {
  width: 25px;
  height: 25px;
  margin-right: 7.5px;
}

.dropdown_icon_background:hover {
  width: 25px;
  height: 25px;
  margin-right: 7.5px;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5); */
}

.dropdown_option {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
  min-height: 50px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.75);
  user-select: none;
}

.dropdown_option:hover {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 1);
}

.rotate_180 {
  transform: rotate(-180deg);
  transition: transform 0.1s ease;
}

@media (max-width: 1200px) {
  .authentication_page {
    width: 100vw;
    height: 100vh;
  }

  .authentication_layout {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  .component_side_layout {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  .authentication_input_side {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    padding: 25px;
    padding-top: 20px;
    padding-bottom: 50px;
    overflow: auto;
  }

  .authentication_input_layout {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    /* height: 100%; */
  }

  .input_options {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    flex: 1;
  }

  .authentication_content_side {
    display: flex;
    width: 30%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    box-sizing: border-box;
    padding: 25px;
    padding-top: 0px;
  }

  .authentication_content_text {
    color: white;
    font-size: 35px;
    font-weight: 200;
    text-align: start;
    user-select: none;
  }

  .home_header_content_right {
    position: absolute;
    display: flex;
    justify-content: end;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .home_components {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .logo_icon {
    height: 50px;
    margin-bottom: 30px;
  }

  .landing_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .cover_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .home_header_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .home_header_content_right {
    position: absolute;
    display: flex;
    justify-content: end;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  /* .page_title {
    color: #0058dc;
    font-weight: 200;
    font-size: 30px;
    margin-bottom: 50px;
  } */
  .page_title {
    color: black;
    font-weight: 200;
    font-size: 25px;
    opacity: 0.75;
    margin: 0;
    margin-bottom: 8px;
    text-align: start;
    user-select: none;
  }

  .signin_page_title {
    color: black;
    font-weight: 200;
    font-size: 25px;
    opacity: 0.75;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    user-select: none;
  }

  .page_subtitle {
    color: black;
    font-weight: 200;
    font-size: 14px;
    margin: 0;
    margin-bottom: 20px;
    opacity: 0.5;
    user-select: none;
  }

  .sidebar {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    background-color: rgba(
      255,
      255,
      255,
      1
    ); /* Semi-transparent white background */

    box-sizing: border-box;

    backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
  }

  .sidebar_right {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    width: 45%;
    height: 100%;
    background-color: rgba(
      255,
      255,
      255,
      1
    ); /* Semi-transparent white background */

    box-sizing: border-box;
    padding: 30px;
    backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
  }

  .sidebar_subscription {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    width: 50%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 1); */
    background-color: rgba(0, 0, 0, 1);

    box-sizing: border-box;
    padding: 30px;
    backdrop-filter: blur(10px); /* Blurring the content behind the sidebar */
  }

  .main_copywriting {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  .two_column_inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .half_input_object {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 48%;
  }

  .input_text_input {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 55px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    /* background: rgba(0, 0, 0, 0.05); */
    margin-bottom: 15px;
    box-sizing: border-box;
    padding-right: 10px;
    font-size: 18px;
  }

  .email_input {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 42px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    /* background: rgba(0, 0, 0, 0.05); */
    margin-bottom: 15px;
    box-sizing: border-box;
    padding-right: 10px;
  }

  .dropdown_input {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 42px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    /* background: rgba(0, 0, 0, 0.05); */
    margin-bottom: 15px;
    box-sizing: border-box;
    padding-right: 10px;

    cursor: pointer;
  }

  .dropdown_input:hover {
    background-color: rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }

  .paragraph_input {
    display: flex;
    justify-content: start;
    align-items: start;
    width: 100%;
    height: 150px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    /* background: rgba(0, 0, 0, 0.05); */
    /* margin-bottom: 15px; */
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
  }

  .input {
    background-color: transparent;
    margin-left: 10px;
    flex-grow: 1;
    border: none;
    outline: none;

    font-family: Arial, Helvetica, sans-serif;
    color: gray;
  }

  .readonly_dropdown_input {
    background-color: transparent;
    margin-left: 10px;
    flex-grow: 1;
    border: none;
    outline: none;
    user-select: none;
    font-family: Arial, Helvetica, sans-serif;
    color: gray;
    cursor: pointer;
  }

  .textarea_input {
    background-color: transparent;
    /* margin-left: 10px; */
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    /* margin-right: 20px; */
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
  }

  .input::placeholder {
    color: black;
    opacity: 0.2;
    font-weight: 400;
  }

  .dropdown_input_option {
    width: 100%;
  }

  .dropdown_input_element {
    border: none;
    outline: none;
    flex-grow: 1;
    margin-left: 15px;
  }

  /* Add this to your CSS file, or create a new CSS file if needed */
  .dropdown_options {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: start;
    border: 1px solid #ccc;
    width: 50%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    background-color: #fff;
  }

  .dropdown_item {
    display: flex;
    justify-content: start;
    /* height: 55px; */
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6);
  }

  .dropdown_item:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .page_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(0, 0, 0, 0);
    color: white;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .pay_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    color: white;
    margin-top: 30px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .pay_button:hover {
    border: 1px solid rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, 1);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .page_button_loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(0, 0, 0, 0);
    color: white;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .page_button:hover {
    border: 1px solid rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, 1);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .page_button_inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    /* background: rgba(0, 0, 0, 0.2); */
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 1);
    opacity: 0.5;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .join_button:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
  }

  .join_button_text {
    font-size: 15px;
    font-weight: 400;
    user-select: none;
  }

  .clickable_text {
    color: rgba(0, 0, 0, 1);
    text-decoration: underline;
    cursor: pointer;
  }

  .loader_layout {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .example_note_gray {
    text-align: start;
    margin: 0;
    font-size: 15px;
    font-weight: 200;
    opacity: 0.75;
    margin-bottom: 25px;
  }

  .logo {
    width: 100px;
    object-fit: cover;
  }

  .login_input_titles {
    /* height: 60px; */
    margin: 0;
    margin-bottom: 8px;
    opacity: 0.4;
    text-align: start;
    user-select: none;
  }

  .error_text {
    color: rgba(200, 0, 0, 0.8);
    margin: 0;
  }

  .auth_input_icon {
    margin-left: 20px;
    width: 16px;
    height: 16px;
    object-fit: cover;
  }

  .subscription_plans {
    width: 100%;
  }

  .profile_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .proflie_picture_section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
  }

  .update_profile_picture {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 30px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* background-color: rgba(0, 0, 0, 0.35); */
    border: 1px solid rgba(0, 0, 0, 0.6);
    margin-left: 30px;
    border-radius: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
  }

  .profile_edit_section {
    display: flex;
  }

  .profile_picture_bg {
    width: 75px;
    height: 75px;
    border-radius: 40%;
  }

  .profile_picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .update_profile_picture:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .prompt_texts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .note {
    color: rgba(0, 0, 0, 0.4);
    opacity: 0.75;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
    /* text-decoration: underline; */
    cursor: pointer;
    margin: 0;
    margin-top: 5px;
  }

  .forgot_password {
    color: rgba(0, 0, 0, 0.4);
    opacity: 0.75;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
    user-select: none;
    cursor: pointer;
    margin: 0;
    text-decoration: underline;
    margin-top: 5px;
  }

  .forgot_password:hover {
    color: rgba(0, 0, 0, 0.7);
    opacity: 0.75;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
    cursor: pointer;
  }

  .page_flexbox {
    display: flex;
    box-sizing: border-box;
    justify-content: start;
    align-items: start;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex: 1;
    /* background-color: red; */
  }

  .payment_options_layout {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
    /* 
    min-height: 300px; */

    /* box-sizing: border-box; */

    width: 100%;
    /* overflow: hidden; */
    padding-bottom: 30px;

    max-height: calc(100vh - 375px);
  }

  .select_options_layout {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: start;
    align-items: start;
    gap: 20px;
    width: 100%;
    overflow: hidden;
    padding-bottom: 30px;
    overflow-y: auto;
    max-height: calc(100vh - 375px);
  }

  .user_input_topics_layout {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    /* padding: 20px; */
    gap: 20px;
    width: 100%;
    /* margin-top: 15px; */
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    /* background-color: rgba(0, 0, 0,  0.1); */
    margin-bottom: 30px;
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 30px;
  }

  .list_option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0px 6px;
    border-radius: 2.5px;
    height: 30px;
    gap: 20px;
    border: 1px solid rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }

  .list_option_selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: rgba(0, 0, 0,  0.1); */
    padding: 5px 10px;
    border-radius: 2.5px;
    height: 30px;
    gap: 20px;
    border: 1px solid rgba(0, 0, 0, 0.7);
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .list_option:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .list_option_text {
    color: black;
    font-size: 12px;
    font-weight: 200;
    margin: 0;
    user-select: none;
  }

  .cancel_icon_bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.01);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    cursor: pointer;
  }

  /* .cancel_icon_bg:hover {
    background-color: rgba(0, 0, 0, 0.075);
  } */

  .cross_svg {
    stroke: rgba(0, 0, 0, 0.3);
    opacity: 0.8;
  }

  /* .spacer {
    display: flex;
  
    background-color: red;
    width: 100%;
    height: 40px;
  } */

  .input_phrase_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 55px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 0 0px;
    margin-bottom: 20px;
  }

  .add_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70%;
    box-sizing: border-box;
    border-radius: 0px;
    color: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    cursor: pointer;
    margin-right: 0px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0 20px;
  }

  .add_button:hover {
    background-color: rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 1);
  }

  .input_banned_phrase {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    margin-left: 10px;
  }

  .payment_option_value_props {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two columns */
    gap: 8px;
    width: 100%;
    align-items: start; /* Align items to the start of each grid cell */
    margin-bottom: 30px;
  }

  .payment_option_value_prop {
    color: black;
    font-size: 15px;
    font-weight: 200;
    margin: 0;
    margin-left: 10px;
    user-select: none;
    /* color: rgba(0, 0, 0, 1); */
    text-align: start;
  }

  .payment_option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    border-radius: 2.5px;
    cursor: pointer;
    gap: 7px;
    position: relative;
  }

  .payment_option:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .payment_option_text {
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-weight: 200;
    margin: 0;
    user-select: none;
  }

  .payment_option_text_price {
    color: black;
    font-size: 20px;
    font-weight: 200;
    margin: 0;
    user-select: none;
    color: rgba(0, 0, 0, 1);
  }

  .payment_option_selected {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 1);
    width: 100%;
    height: 100%;
    border-radius: 2.5px;
    gap: 7px;
    position: relative;
  }

  .check_icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    transform: translate(50%, -50%);
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 1);
    box-sizing: border-box;
    padding: 4px;
  }

  .progress_layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    height: 55px;
    margin-top: 25px;
  }

  .progress_bar {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 1);
  }

  .progress_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 55px;
    /* min-height: 55px; */
    border-radius: 55px;
    /* border: 1px solid rgba(0, 0, 0, 1); */
  }

  .progress_percent {
    margin: 0;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
  }

  .checkout_form {
    width: 100%;
  }

  .valueProp_layout {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .dropdown_menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 100%;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    z-index: 999999;
    max-height: 180px;
    overflow-y: auto;
  }

  .dropdown_icon {
    width: 25px;
    height: 25px;
    margin-right: 7.5px;
  }

  .dropdown_icon_background {
    width: 25px;
    height: 25px;
    margin-right: 7.5px;
  }

  .dropdown_icon_background:hover {
    width: 25px;
    height: 25px;
    margin-right: 7.5px;
    /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5); */
  }

  .dropdown_option {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 10px;
    box-sizing: border-box;
    width: 100%;
    min-height: 50px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.75);
    user-select: none;
  }

  .dropdown_option:hover {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 1);
  }

  .rotate_180 {
    transform: rotate(-180deg);
    transition: transform 0.1s ease;
  }
}

@media (max-width: 900px) {
  .authentication_content_text {
    color: white;
    font-size: 18px;
    font-weight: 200;
    text-align: start;
    user-select: none;
  }
}

/* //mobile */
@media (max-width: 600px) {
  .component_side_layout {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .authentication_input_side {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    border-left: 2px solid rgba(0, 0, 0, 1);
    box-sizing: border-box;
    padding-top: 10px;
    padding: 22px;
    padding-bottom: 30px;
    overflow: auto;
  }
}
