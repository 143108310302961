.landing_page {
  background-color: white;
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 75px;
  padding-top: 120px;
}

.page_header {
  display: flex;
  /* background-color: white; */
  box-sizing: border-box;
}

.page_titles {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.page_title {
  color: black;
  font-size: 60px;
  margin: 0;
}

.page_subtitle {
  color: black;
  font-size: 22px;
  margin: 0;
}

.page_description {
  color: black;
  font-size: 20px;
  margin: 0;
  opacity: 0.75;
  text-align: start;
  width: 75%;
}

.page_stats {
}

.page_line_break {
  display: flex;
  height: 1px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  margin-bottom: 50px;
}

.career_options {
  margin-top: 25px;
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 25px;
  justify-items: center;
  align-items: center;
  width: 100%;
}

.pricing_tile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  height: 600px;
  box-sizing: border-box;
  padding: 35px 35px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3) !important;
}

.pricing_tile_top {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
}

.career_image {
  width: 50px;
  height: 50px;
}

.career_title {
  margin: 0;
  color: black;
  font-size: 28px;
  font-weight: 200;
  text-align: start;
  align-self: start;
  margin-bottom: 4px;
}

.career_subtitle {
  margin: 0;
  color: black;
  font-size: 14px;
  font-weight: 200;
  text-align: start;
  align-self: start;
}

.details_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

.details_word {
  margin: 0;
  color: black;
  font-size: 12px;
  font-weight: 200;
  opacity: 0.5;
  margin-right: 15px;
}

.details_line {
  height: 1px;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

.career_description {
  margin: 0;
  font-weight: 400;
  color: black;
  font-size: 20px;
  opacity: 0.8;
  margin-bottom: 8px;
}

.cta_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(243, 201, 60);
  color: white;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0);
  width: 100%;
  height: 60px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
}

.cta_button:hover {
  background-color: white;
  color: black;
  border: 1px solid rgba(0, 0, 0, 1);
}

@media (max-width: 1024px) {
  .landing_page {
    background-color: black;
    min-height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    padding: 25px;
    padding-top: 100px;
    position: relative;
  }

  .page_header {
    display: flex;
    /* background-color: white; */
    box-sizing: border-box;
  }

  .page_titles {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  .page_title {
    color: white;
    font-size: 40px;
    margin: 0;
    text-align: start;
  }

  .page_subtitle {
    color: white;
    font-size: 12px;
    margin: 0;
    text-align: start;
    font-weight: 600;
  }

  .page_description {
    color: white;
    font-size: 13px;
    margin: 0;
    opacity: 0.75;
    text-align: start;
    width: 100%;
  }

  .page_stats {
  }

  .page_line_break {
    display: flex;
    height: 1px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 50px;
    margin-bottom: 125px;
  }

  .mission_page_content {
    width: 100%;
  }

  .page_section {
    display: flex;
    justify-content: space-between;
  }

  .left_section {
    width: 50%;
    height: 600px;
  }

  .right_section {
    width: 50%;
    height: 600px;
  }

  .section_image {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }

  .page_image_section {
    display: flex;
    justify-content: flex-start;
  }
  .section_image_big {
    width: 50vw;
    object-fit: cover;
    transform: rotate(180deg);
    margin-left: -200px;
  }

  .section_title {
    color: white;
    margin: 0;
    font-size: 35px;
    font-family: 200;
    margin-bottom: 10px;
    text-align: start;
  }

  .section_text {
    color: white;
    margin: 0;
    opacity: 0.8;
    font-size: 25px;
    font-weight: 200;
    text-align: start;
  }
}
