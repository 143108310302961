.landing_page {
  /* background-color: rgb(243, 201, 60); */
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background-color: white;
  background-color: black;
  overflow: hidden;
  /* padding: 75px; */
  /* padding-top: 120px; */
}

.video_container {
  position: relative;
  height: 55vh;
  width: 100vw;
}

.nexusVideo {
  height: 100%;
  width: 100vw;
  object-fit: cover;
  object-position: center;
}

.page_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  justify-content: start;
  background-color: white;
  box-sizing: border-box;
  /* background-color: black; */
  /* height: 1200px; */
}

.page_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: start;
  background-color: white;
  box-sizing: border-box;
  box-sizing: border-box;
  padding: 75px;
  padding-top: 0;
  margin-top: -50px;
}

.video_title {
  position: absolute;
  margin: 0;
  left: 50px;
  bottom: 50px;
  color: white;
  font-size: 80px;
  font-weight: 200;
}

.page_titles {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  flex: 1;
  /* margin-top: 50px; */
  box-sizing: border-box;
  padding: 50px;
}

.page_title {
  color: black;
  font-size: 50px;
  font-weight: 200;
  margin: 0;
  text-align: start;
}

.page_subtitle {
  color: black;
  font-size: 22px;
  margin: 0;
  text-align: start;
}

.black_square_background {
  height: 100vh;
  margin-right: -50px;
  opacity: 1;
}
.page_description {
  color: black;
  font-size: 20px;
  margin: 0;
  opacity: 0.75;
  text-align: start;
  width: 75%;
}

.page_stats {
}

.page_line_break {
  display: flex;
  height: 1px;
  box-sizing: border-box;
  background-color: white;
  margin-top: 50px;
  margin-bottom: 50px;
}

.career_options {
  margin-top: 25px;
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 25px;
  justify-items: center;
  align-items: center;
  width: 100%;
}

.career_tile {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  height: 600px;
  box-sizing: border-box;
  padding: 30px 40px;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3) !important;
}

.career_image {
  width: 50px;
  height: 50px;
}

.career_title {
  margin: 0;
  color: black;
  font-size: 28px;
  font-weight: 200;
}

.career_description {
  margin: 0;
  font-weight: 400;
  color: black;
  font-size: 20px;
  opacity: 0.8;
  margin-bottom: 8px;
}

.cta_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0);
  width: 150px;
  height: 50px;
  cursor: pointer;
}

.cta_button:hover {
  background-color: white;
  color: black;
  border: 1px solid rgba(0, 0, 0, 1);
}

.header_content_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  height: 500px;
  width: calc(100% + 200px);
  margin-left: -100px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.275);
  padding-top: 50px;
  padding-left: 100px;
}

.enterprise_layout {
  /* margin-top: 25px; */
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 25px;
  justify-items: center;
  align-items: center;
  width: 100%;
  /* margin-bottom: 100px; */
}

.enterprise_prop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
  width: 100%;
}

.enterprise_prop_text {
  margin: 0;
  font-size: 16px;
  text-align: start;
}

.page_body_titles {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 40px;
}

.body_title {
  margin: 0;
  color: black;
  text-align: start;
  font-size: 25px;
}

.body_description {
  font-size: 22px;
  margin: 0;
  color: black;
  text-align: start;
}

.banner_side_content {
  height: 100%;
  width: 60%;
}

.item_hand_image {
  height: 100%;
}

.white_benefits_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 80vw;
  /* height: 600px; */
  background-color: white;
  z-index: 9999;

  box-sizing: border-box;
}

.benefit_top {
  /* height: 300px; */
  margin-bottom: 50px;
}

.benefit_title {
  margin: 0;
  color: black;
  font-size: 28px;
  font-weight: 100;
  text-align: start;
  width: 70%;
}

.benefit_section_divider {
  display: flex;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  height: 1px;
}

.benefit_section {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.benefit_section_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* height: 100px; */
  box-sizing: border-box;
  padding: 30px 0px;
}

.benefit_section_item_left {
  display: flex;
  align-items: center;
  gap: 30px;
}

.benefit_description_title {
  font-size: 26px;
  font-weight: 300;
}

.benefit_description {
  font-size: 20px;
  font-weight: 300;
  width: 40%;
  text-align: start;
}

.benefit_section_image {
  height: 90px;
  width: 210px;
  object-fit: cover;
  border-radius: 5px;
}

.image_section_text_box {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
  box-sizing: border-box;
  padding-right: 10%;
  height: 100%;
}

.gray_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100vw;
  background-color: white;
  padding: 75px;
  box-sizing: border-box;
  /* height: 2000px; */
}

.enterprise_industry_examples_layout {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  box-sizing: border-box;
  gap: 25px;
  justify-items: center;
  align-items: center;
  width: 100%;
}

.indstury {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100%;
}

.industry_image {
  width: 100%;
  height: 200px;
}

.industry_text {
  margin: 0;
  font-weight: 300;
  color: black;
  align-self: start;
}

.industry_description_text {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.75);
  text-align: start;
}

.industry_title {
  margin: 0;
  font-size: 25px;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 5px;
}

.industry_subtitle {
  margin: 0;
  font-size: 22px;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.75);
  width: 50%;
  margin-bottom: 40px;
}
