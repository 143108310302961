.landing_page {
  background-color: black;
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 75px;
  padding-top: 150px;
  position: relative;
}

.company_page {
  width: 100vw;
  box-sizing: border-box;
  min-height: 275vh;
  padding: 75px;
  padding-top: 150px;
  padding-bottom: 0px;
  position: relative;
}

.top_padding_spacer {
  width: 100vw;
  box-sizing: border-box;
}

.page_header {
  display: flex;
  /* background-color: white; */
  box-sizing: border-box;
}

.page_titles {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.page_title {
  color: white;
  font-size: 150px;
  margin: 0;
}

.page_subtitle {
  color: white;
  font-size: 40px;
  font-weight: 200;
  margin: 0;
}

.page_description {
  color: white;
  font-size: 22px;
  margin: 0;
  opacity: 0.75;
  text-align: start;
  width: 70%;
}

.page_stats {
}

.page_line_break {
  display: flex;
  height: 1px;
  box-sizing: border-box;
  background-color: white;
  margin-top: 50px;
  margin-bottom: 50px;
}

.portfolio_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

.company_tile {
  height: 300px;
  position: relative;
  cursor: pointer;
}

.company_tile:hover {
  /* background-color: rgba(255, 255, 255, 0.03); */
}

.company_hover_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  width: 100%;
}

.info_underline {
  margin-top: 25px;
  width: 100%;
  height: 1px;
}

.company_description {
  color: white;
  margin: 0;
  font-size: 16px;
  font-weight: 200;
  opacity: 0.7;
  margin-top: -10px;
  text-align: start;
}

.view_company_button {
  border: 1px solid white;
}

.company_tile:hover {
  /* background-color: rgba(255, 0, 0, 0.5); */
}

.company_tile::after {
  content: "";
  position: absolute;
  right: -25px;
  top: 0;
  bottom: 0;
  width: 1px;
  /* background-color: white; */
}

.company_tile:nth-child(3n)::after {
  display: none;
}

.company_logo_background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 100%;

  opacity: 1;
}

.company_name_placeholder {
  font-size: 35px;
  color: white;
}

.company_logo {
  object-fit: cover;
  height: 50%;
  width: auto;
}

.company_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 30%;
  gap: 5px;
}

.company_title {
  color: white;
  margin: 0;
  font-size: 25px;
}

.company_subtitle {
  color: white;
  margin: 0;
  font-size: 20px;
  opacity: 0.7;
  text-align: start;
}

.company_info_text {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.view_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  /* border: 1px solid rgba(255, 255, 255, 0); */
}

.view_button:hover {
  background-color: white;
  border: 1px solid rgba(255, 255, 255, 0);
}

.view_arrow {
  width: 30%;
  height: 30%;
  object-fit: cover;
}

/* ------------------ ANIMATIONS ------------------ */

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.slideOut {
  animation: slideOutToLeft 0.5s forwards;
  position: absolute;
  top: 0;
  width: 100%;
}

.slideIn {
  animation: slideInFromRight 0.5s forwards;
  position: absolute;
  top: 0;
  width: 100%;
  height: 200vh;
}

/* Company Hover Animations */

.company_tile {
  transition: all 0.3s ease;
  cursor: pointer;
}

.company_logo_background {
  transition: height 0.3s ease;
}

.company_tile:hover .company_logo_background {
  height: 80px;
  /* opacity: 0.5; */
}

.company_info {
  transition: transform 0.3s ease;
}

/* Initial state for .company_hover_info - invisible */
.company_hover_info {
  transition: opacity 0.1s ease, transform 0.3s ease;
  opacity: 0;
  /* transform: translateY(10px); */
  pointer-events: none;
}

/* On hover, make .company_hover_info visible and move up */
.company_tile:hover .company_hover_info {
  opacity: 1; /* Make it fully visible */
  transform: translateY(0); /* Return to its original position */
}

/* ------------------ Company Page ------------------ */

.back_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  margin-bottom: 20px;
  cursor: pointer;
  /* border: 1px solid rgba(255, 255, 255, 0); */
}

.back_button:hover {
  background-color: white;
  border: 1px solid rgba(255, 255, 255, 0);
}

.back_arrow {
  width: 30%;
  height: 30%;
  object-fit: cover;
  transform: rotate(180deg);
  cursor: pointer;
}

.company_page_logo {
  width: 500px;
}

.company_info_section {
  display: flex;
  align-items: center;
  /* height: 100px; */
  width: 100%;
  justify-content: space-between;
}

.company_point {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 5px;
}

.point_title {
  color: white;
  margin: 0;
  font-weight: 400;
  font-size: 22px;
}

.point_value {
  margin: 0;
  color: white;
  font-weight: 200;
  font-size: 32px;
  opacity: 0.85;
}

.about_company_section {
  /* height: 300px; */
}

.company_page_description_box {
  width: 50%;
}

.company_page_description_text {
  text-align: start;
  margin: 0;
  color: white;
  font-weight: 200;
  font-size: 32px;
}

.company_page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: white; */
  box-sizing: border-box;
}

.visit_site_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.visit_site_button:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  opacity: 1;
}

.whiteFullArrowUpRight {
  height: 15px;
  width: 15px;
}

.about_company_section {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
}

.company_information {
  display: flex;
  flex-direction: column;
  width: 60%;
  text-align: start;
}

.career_section {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: start;
}

.about_us_title {
  color: white;
  font-size: 50px;
  font-weight: 100;
  margin: 0;
  margin-bottom: 10px;
}

.company_information_text {
  color: white;
  font-size: 30px;
  font-weight: 400;
  margin: 0;
  text-align: start;
  margin-bottom: 50px;
}

.company_image {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .landing_page {
    background-color: black;
    min-height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    padding: 25px;
    padding-top: 100px;
    position: relative;
  }

  .company_page {
    min-height: 190vh;
    width: 100vw;
    box-sizing: border-box;
    padding: 25px;
    padding-top: 80px;
    position: relative;
  }

  .page_header {
    display: flex;
    /* background-color: white; */
    box-sizing: border-box;
  }

  .page_titles {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  .page_title {
    color: white;
    font-size: 50px;
    margin: 0;
    text-align: start;
  }

  .page_subtitle {
    color: white;
    font-size: 17px;
    margin: 0;
    text-align: start;
    font-weight: 300;
  }

  .page_description {
    color: white;
    font-size: 13px;
    margin: 0;
    opacity: 0.75;
    text-align: start;
    width: 100%;
  }

  .page_stats {
  }

  .page_line_break {
    display: flex;
    height: 1px;
    box-sizing: border-box;
    background-color: white;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .portfolio_grid {
    display: grid;
    /* Use auto-fit or auto-fill with minmax to define minimum and maximum column sizes */
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 50px;
  }

  .company_tile {
    height: 300px;
    position: relative;
    cursor: pointer;
  }

  .company_tile:hover {
    /* background-color: rgba(255, 255, 255, 0.03); */
  }

  .company_hover_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    width: 100%;
  }

  .info_underline {
    margin-top: 25px;
    width: 100%;
    height: 1px;
  }

  .company_description {
    color: white;
    margin: 0;
    font-size: 16px;
    font-weight: 200;
    opacity: 0.7;
    margin-top: -10px;
    text-align: start;
  }

  .view_company_button {
    border: 1px solid white;
  }

  .company_tile:hover {
    /* background-color: rgba(255, 0, 0, 0.5); */
  }

  .company_tile::after {
    content: "";
    position: absolute;
    right: -25px;
    top: 0;
    bottom: 0;
    width: 1px;
    /* background-color: white; */
  }

  .company_tile:nth-child(3n)::after {
    display: none;
  }

  .company_logo_background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 100%;

    opacity: 1;
  }

  .company_name_placeholder {
    font-size: 35px;
    color: white;
  }

  .company_logo {
    object-fit: cover;
    height: 50%;
    width: auto;
  }

  .company_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 30%;
    gap: 5px;
  }

  .company_title {
    color: white;
    margin: 0;
    font-size: 25px;
  }

  .company_subtitle {
    color: white;
    margin: 0;
    font-size: 20px;
    opacity: 0.7;
  }

  .company_info_text {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .view_button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    /* border: 1px solid rgba(255, 255, 255, 0); */
  }

  .view_button:hover {
    background-color: white;
    border: 1px solid rgba(255, 255, 255, 0);
  }

  .view_arrow {
    width: 30%;
    height: 30%;
    object-fit: cover;
  }

  /* ------------------ ANIMATIONS ------------------ */

  @keyframes slideOutToLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  .slideOut {
    animation: slideOutToLeft 0.5s forwards;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .slideIn {
    animation: slideInFromRight 0.5s forwards;
    position: absolute;
    top: 0;
    width: 100%;
    height: 200vh;
  }

  /* Company Hover Animations */

  .company_tile {
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .company_logo_background {
    transition: height 0.3s ease;
  }

  .company_tile:hover .company_logo_background {
    height: 80px;
    /* opacity: 0.5; */
  }

  .company_info {
    transition: transform 0.3s ease;
  }

  /* Initial state for .company_hover_info - invisible */
  .company_hover_info {
    transition: opacity 0.1s ease, transform 0.3s ease;
    opacity: 0;
    /* transform: translateY(10px); */
    pointer-events: none;
  }

  /* On hover, make .company_hover_info visible and move up */
  .company_tile:hover .company_hover_info {
    opacity: 1; /* Make it fully visible */
    transform: translateY(0); /* Return to its original position */
  }

  /* ------------------ Company Page ------------------ */

  .back_button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    width: 50px;
    height: 50px;
    border-radius: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    /* border: 1px solid rgba(255, 255, 255, 0); */
  }

  .back_button:hover {
    background-color: white;
    border: 1px solid rgba(255, 255, 255, 0);
  }

  .back_arrow {
    width: 30%;
    height: 30%;
    object-fit: cover;
    transform: rotate(180deg);
    cursor: pointer;
  }

  .company_page_logo {
    width: 300px;
  }

  /* .company_info_section {
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
    justify-content: space-between;
  } */

  .company_info_section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    height: 400px;
    width: 100%;
    justify-content: space-between;
  }

  .company_point {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 5px;
  }

  .point_title {
    color: white;
    margin: 0;
    font-weight: 400;
    font-size: 22px;
    text-align: start;
  }

  .point_value {
    margin: 0;
    color: white;
    font-weight: 200;
    font-size: 32px;
    opacity: 0.85;
    text-align: start;
  }

  .about_company_section {
    height: 300px;
  }

  .company_page_description_box {
    width: 100%;
  }

  .company_page_description_text {
    text-align: center;
    margin: 0;
    color: white;
    font-weight: 200;
    font-size: 20px;
  }

  .company_page_header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: white; */
    box-sizing: border-box;
  }

  .visit_site_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 220px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }

  .visit_site_button:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    opacity: 1;
  }

  .whiteFullArrowUpRight {
    height: 15px;
    width: 15px;
  }
}
