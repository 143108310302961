.home_page_landing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: white;
  z-index: 0;
  box-sizing: border-box;
  width: 100vw;
}

.top_header_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  position: relative;
}

.shadow_background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
}

.landing_title {
  font-size: 40px;
  margin: 0;
  margin-bottom: 20px;
  color: white;
}

.landing_main_cta {
  border: 1px solid rgba(255, 255, 255, 1);
  font-size: 20px;
  margin: 0;
  color: white;
  font-weight: 200;
  padding: 15px 50px;
  border-radius: 30px;
  cursor: pointer;
  /* background-color: white; */
}

.landing_main_cta:hover {
  background-color: white;
  color: black;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.75);
}

.top_header_image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .landing_title {
    font-size: 30px;
  }
  .landing_main_cta {
    border: 1px solid rgba(255, 255, 255, 1);
    font-size: 20px;
    margin: 0;
    color: white;
    font-weight: 200;
    padding: 12px 40px;
    border-radius: 30px;
    cursor: pointer;
    /* background-color: white; */
  }
}
