.faq_components {
  width: 100vw;
  padding-top: 150px;
  background-color: black;
}

.faq_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title {
  font-size: 35px;
  font-weight: 400;
  margin: 0;
  color: white;
}

.subtitle {
  font-size: 23px;
  font-weight: 200;
  width: 50%;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
}

.faq_list_items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.faq_tile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
  width: 80vw;
  height: 75px;
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3) !important;
  margin-top: 25px;
}

.faq_tile:hover {
  background-color: rgba(255, 255, 255, 0.95);
}

.item_question {
  margin: 0;
  width: 100%;
  text-align: left;
}

.down_arrow_image {
  width: 25px;
  height: 25px;
}

.down_arrow_image {
  transition: transform 0.3s ease-in-out; /* smooth transition */
}

.flipped {
  transform: rotate(180deg); /* rotate the image 180 degrees */
}

.answer_text {
  margin: 0;
  color: white;
  text-align: left;
  margin-top: 15px;
}

.spacer {
  height: 125px !important;
  width: 100vw !important;
}
.hubspot_quote_text {
  font-style: italic;
}
