.landing_page {
  background-color: black;
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 75px;
  padding-top: 150px;
}

.page_header {
  display: flex;
  flex-direction: row;
  /* background-color: white; */
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}

.page_titles {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.page_title {
  color: white;
  font-size: 120px;
  margin: 0;
}

.page_subtitle {
  color: white;
  font-size: 22px;
  margin: 0;
}

.page_description {
  color: white;
  font-size: 20px;
  margin: 0;
  opacity: 0.75;
  text-align: start;
}

.page_inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  gap: 20px;
}

.text_input {
  /* background-color: white; */
  width: 100%;
  height: 55px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.paragraph_input {
  /* background-color: white; */
  width: 100%;
  height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.page_line_break {
  display: flex;
  height: 1px;
  box-sizing: border-box;
  background-color: white;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 50%;
}

.portfolio_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

.company_tile {
  height: 250px;
  position: relative;
}

.company_tile::after {
  content: "";
  position: absolute;
  right: -25px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: white;
}

.company_tile:nth-child(3n)::after {
  display: none;
}

.company_image {
  height: 70%;
  opacity: 0.3;
}

.company_info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 30%;
  gap: 5px;
}

.company_title {
  color: white;
  margin: 0;
  font-size: 25px;
}

.company_subtitle {
  color: white;
  margin: 0;
  font-size: 20px;
  opacity: 0.7;
}
