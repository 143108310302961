.top_padding_spacer {
  min-height: 150px;
  max-height: 150px;
}

.flickerText {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.flickerText:hover::after {
  content: "";
  position: absolute;
  bottom: -7.5px;
  left: 0;
  right: 0;
  height: 1px;
  background: currentColor;
  animation: underlineSlide 0.5s forwards;
}

.flickerText:hover span {
  animation: flickerEffect 0.025s forwards;
  user-select: none;
}

@keyframes flickerEffect {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes underlineSlide {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
