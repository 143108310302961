.landing_page {
  background-color: black;
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 75px;
  padding-top: 150px;
}

.section_space_divider {
  min-height: 100px;
}

.page_header {
  display: flex;
  /* background-color: white; */
  box-sizing: border-box;
}

.page_titles {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.page_title {
  color: white;
  font-size: 150px;
  margin: 0;
}

.page_subtitle {
  color: white;
  font-size: 22px;
  margin: 0;
}

.page_description {
  color: white;
  font-size: 28px;
  margin: 0;
  opacity: 0.8;
  text-align: start;
  font-weight: 200;
}

.page_stats {
}

.page_line_break {
  display: flex;
  height: 1px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 50px;
  margin-bottom: 125px;
}

.mission_page_content {
  width: 100%;
}

.page_section {
  display: flex;
  justify-content: space-between;
}

.left_section {
  width: 50%;
  /* height: 600px; */
}

.right_section {
  width: 50%;
  /* height: 600px; */
}

.section_image {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.page_image_section {
  display: flex;
  justify-content: flex-start;
}
.section_image_big {
  width: 50vw;
  object-fit: cover;
  transform: rotate(180deg);
  margin-left: -200px;
}

.section_title {
  color: white;
  margin: 0;
  font-size: 35px;
  font-family: 200;
  margin-bottom: 10px;
  text-align: start;
}

.section_text {
  color: white;
  margin: 0;
  opacity: 0.8;
  font-size: 25px;
  font-weight: 200;
  text-align: start;
}

.bottom_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* min-height: 300px; */
}

.bottom_section_left {
  width: 40%;
  height: 100%;
  box-sizing: border-box;
}

.vertical_image {
  width: 100%;
  height: 700px;
  object-fit: cover;
  margin-bottom: 65px;
}

.bottom_text_title {
  width: 100%;
  margin: 0;
  color: white;
  text-align: start;
  font-size: 28px;
}

.short_text {
  color: white;
  margin: 0;
  opacity: 0.8;
  font-size: 25px;
  font-weight: 200;
  text-align: start;
}

.bottom_section_right {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 45%;
  box-sizing: border-box;
  justify-content: space-between;
  padding-left: 100px;
}

.horizontal_image {
  width: 100%;
  min-height: 300px;
  object-fit: cover;
}

.long_text {
  color: white;
  margin: 0;
  opacity: 0.8;
  font-size: 25px;
  font-weight: 200;
  text-align: start;
}

.flex_spacer {
  display: flex;
  flex: 1;
}

.text_boxes {
  display: flex;
  flex-direction: column;
}

.section_line_break {
  display: flex;
  height: 1px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 50px;
  margin-bottom: 50px;
}

.blueprint_section_items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 25px;
  justify-items: center;
  align-items: center;
  width: 100%;
}

.blueprint_item {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  gap: 10px;
  /* padding: 30px 40px; */
  /* box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3) !important; */
}

.blueprint_section_title {
  color: white;
  margin: 0;
  font-size: 45px;
  font-family: 200;
  margin-bottom: 5px;
  text-align: start;
}

.blueprint_section_subtitle {
  color: white;
  margin: 0;
  font-weight: 200;
  font-size: 35px;
  font-family: 200;
  margin-bottom: 50px;
  text-align: start;
}

.blueprint_section_number {
  color: white;
  margin: 0;
  font-weight: 400;
  font-size: 45px;
  font-family: 200;
  text-align: start;
}

.blueprint_section_stage {
  color: white;
  margin: 0;
  font-weight: 100;
  font-size: 35px;
  font-family: 200;
  text-align: start;
}

.blueprint_section_description {
  color: white;
  margin: 0;
  font-weight: 300;
  font-size: 25px;
  font-family: 200;
  text-align: start;
}

@media (max-width: 1024px) {
  .landing_page {
    background-color: black;
    min-height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    padding: 25px;
    padding-top: 100px;
    position: relative;
  }

  .page_header {
    display: flex;
    /* background-color: white; */
    box-sizing: border-box;
  }

  .page_titles {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  .page_title {
    color: white;
    font-size: 50px;
    margin: 0;
    text-align: start;
  }

  .page_subtitle {
    color: white;
    font-size: 12px;
    margin: 0;
    text-align: start;
    font-weight: 600;
  }

  .page_description {
    color: white;
    font-size: 13px;
    margin: 0;
    opacity: 0.75;
    text-align: start;
    width: 100%;
  }

  .page_stats {
  }

  .page_line_break {
    display: flex;
    height: 1px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
    margin-top: 50px;
    margin-bottom: 125px;
  }

  .mission_page_content {
    width: 100%;
  }

  .page_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .left_section {
    margin-top: -40px;
    width: 100%;
    /* height: 200px; */
  }

  .right_section {
    margin-top: 50px;
    width: 100%;
    /* height: 600px; */
  }

  .section_image {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }

  .page_image_section {
    display: flex;
    justify-content: flex-start;
  }

  .section_image_big {
    width: 90vw;
    object-fit: cover;
    transform: rotate(180deg);
    margin-left: -200px;
  }

  .section_title {
    color: white;
    margin: 0;
    font-size: 35px;
    font-family: 200;
    margin-bottom: 10px;
    text-align: start;
  }

  .section_text {
    color: white;
    margin: 0;
    opacity: 0.8;
    font-size: 25px;
    font-weight: 200;
    text-align: start;
  }

  .bottom_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    /* min-height: 300px; */
  }

  .bottom_section_left {
    width: 100%;
    /* height: 100%; */
    box-sizing: border-box;
  }

  .vertical_image {
    width: 100%;
    height: 0px;
    object-fit: cover;
    margin-bottom: 65px;
  }

  .bottom_text_title {
    width: 100%;
    margin: 0;
    color: white;
    text-align: start;
    font-size: 28px;
  }

  .short_text {
    color: white;
    margin: 0;
    opacity: 0.8;
    font-size: 25px;
    font-weight: 200;
    text-align: start;
  }

  .bottom_section_right {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    padding-left: 0px;
    margin-top: 30px;
  }

  .horizontal_image {
    width: 100%;
    min-height: 300px;
    object-fit: cover;
    margin-top: 30px;
  }

  .long_text {
    color: white;
    margin: 0;
    opacity: 0.8;
    font-size: 25px;
    font-weight: 200;
    text-align: start;
  }

  .flex_spacer {
    display: flex;
    flex: 1;
  }

  .text_boxes {
    display: flex;
    flex-direction: column;
  }

  .section_line_break {
    display: flex;
    height: 1px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .blueprint_section_items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 25px;
    justify-items: center;
    align-items: center;
    width: 100%;
  }

  .blueprint_item {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    gap: 10px;
    /* padding: 30px 40px; */
    /* box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3) !important; */
  }

  .blueprint_section_title {
    color: white;
    margin: 0;
    font-size: 45px;
    font-family: 200;
    margin-bottom: 5px;
    text-align: start;
  }

  .blueprint_section_subtitle {
    color: white;
    margin: 0;
    font-weight: 200;
    font-size: 35px;
    font-family: 200;
    margin-bottom: 50px;
    text-align: start;
  }

  .blueprint_section_number {
    color: white;
    margin: 0;
    font-weight: 400;
    font-size: 45px;
    font-family: 200;
    text-align: start;
  }

  .blueprint_section_stage {
    color: white;
    margin: 0;
    font-weight: 100;
    font-size: 35px;
    font-family: 200;
    text-align: start;
  }

  .blueprint_section_description {
    color: white;
    margin: 0;
    font-weight: 300;
    font-size: 25px;
    font-family: 200;
    text-align: start;
  }
}
